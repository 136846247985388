<i18n>
{
  "nl-BE": {
    "title": "Wat is het adres van het te financieren pand?",
    "tooltip_text": "Selecteer het adres van het pand dat wordt gefinancierd. Gelieve dit niet te verwarren met het adres van eventuele andere panden die gebruikt worden als extra zekerheid.",
    "control_address": "Vervolledig het adres indien nodig",
    "street": "Straat",
    "number": "Nummer",
    "box": "Bus",
    "postalcode": "Postcode",
    "municipality": "Stad",
    "previous": "Vorige",
    "next": "Volgende",
    "field_required": "Dit veld is verplicht",
    "only_numbers": "Gelieve enkel cijfers in te vullen",
    "min_4_characters": "Dit veld vereist min. 4 karakters"
  },
  "en-BE": {
    "title": "What is the address of the property being financed?",
    "tooltip_text": "Select the address of the property that is being financed. Please do not confuse this with the address of possible other properties that are being used as additional collateral." ,
    "control_address": "Complete the address if needed",
    "street": "Street",
    "number": "Number",
    "box": "Box number",
    "postalcode": "Postal code",
    "municipality": "City",
    "previous": "Previous",
    "next": "Next",
    "field_required": "This field is required",
    "only_numbers": "Please enter only numeric values",
    "min_4_characters": "Input should contain min. 4 characters"
  },
  "fr-BE": {
    "title": "Quelle est l'adresse du bien financé ?",
    "tooltip_text": "Sélectionnez l'adresse du bien financé. Merci de ne pas confondre ceci avec l'adresse d'autres bien éventuels qui peuvent servir comme sûreté.",
    "control_address": "Complétez l'adresse si nécessaire",
    "street": "Rue",
    "number": "Numéro",
    "box": "Boîte",
    "postalcode": "Code postal",
    "municipality": "Commune",
    "previous": "Précédent",
    "next": "Suivant",
    "field_required": "Ce champ est obligatoire",
    "only_numbers": "Veuillez entrer uniquement des chiffres",
    "min_4_characters": "Ce champ requiert 4 caractères au minimum"
  }
}
</i18n>

<template>
    <div class="container section address-section">
        <title-with-tooltip :label="$t('tooltip_text')">
            <template #title>{{ $t('title') }}</template>
        </title-with-tooltip>
        <div class="columns is-mobile is-multiline pt-5 pb-1">
            <div class="column is-10-tablet is-12-mobile">
                <auto-complete
                    :placeholder="getAddress.full_address"
                    :read-only="!isDraft"
                    @selectedItem="setAddress($event)"
                />
            </div>
            <div v-if="isActive" class="column is-10-tablet is-12-mobile pt-3 ">
                <h3 class="has-text-weight-medium has-text-black pt-5 mt-1">
                    {{ $t('control_address') }}
                </h3>
            </div>

            <div v-if="isActive" id="extra" class="column is-10-tablet is-12-mobile ">
                <!-- streetname input section -->
                <div class="columns">
                    <div class="column is-12">
                        <b-field
                            class="mt-1"
                            :label="$t('street')"
                            expanded
                            :type="{ 'is-danger': $v.addressInfo.streetname.$error }"
                            :message="[
                                !$v.addressInfo.streetname.required &&
                                $v.addressInfo.streetname.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="streetname"
                                size="is-medium"
                                placeholder="Kleine Schuurstraat"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.streetname.$touch()"
                                @input="$v.addressInfo.streetname.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-variable is-multiline is-6">
                    <!-- streetnumber input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('number')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.streetnumber.$error,
                            }"
                            :message="[
                                !$v.addressInfo.streetnumber.required &&
                                $v.addressInfo.streetnumber.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="streetnumber"
                                size="is-medium"
                                placeholder="8"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.streetnumber.$touch()"
                                @input="$v.addressInfo.streetnumber.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- box number input section -->
                    <div class="column is-6">
                        <b-field :label="$t('box')" expanded>
                            <b-input
                                v-model="boxnumber"
                                size="is-medium"
                                placeholder="123"
                                :disabled="!isDraft"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- postalcode input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('postalcode')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.postalcode.$error,
                            }"
                            :message="[
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.required
                                    ? $t('field_required')
                                    : null,
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.minLength
                                    ? $t('min_4_characters')
                                    : null,
                                $v.addressInfo.postalcode.$error &&
                                !$v.addressInfo.postalcode.numeric
                                    ? $t('only_numbers')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="postalcode"
                                size="is-medium"
                                placeholder="0960"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.postalcode.$touch()"
                                @input="$v.addressInfo.postalcode.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                    <!-- municipality input section -->
                    <div class="column is-6">
                        <b-field
                            :label="$t('municipality')"
                            expanded
                            :type="{
                                'is-danger': $v.addressInfo.municipality.$error,
                            }"
                            :message="[
                                !$v.addressInfo.municipality.required &&
                                $v.addressInfo.municipality.$error
                                    ? $t('field_required')
                                    : null,
                            ]"
                        >
                            <b-input
                                v-model="municipality"
                                size="is-medium"
                                placeholder="Gent"
                                :disabled="!isDraft"
                                @blur="$v.addressInfo.municipality.$touch()"
                                @input="$v.addressInfo.municipality.$touch()"
                            ></b-input>
                        </b-field>
                    </div>
                </div>
            </div>
            <div class="column is-10-tablet is-12-mobile mt-5">
                <div class="is-flex is-justify-content-space-between">
                    <b-button
                        tag="router-link"
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        :to="{
                            name: 'request',
                            params: {
                                lang: $route.params.lang,
                                action: 'building-type',
                            },
                            query: $route.query,
                        }"
                    >
                        <span>{{ $t('previous') }}</span>
                    </b-button>
                    <b-button
                        v-if="isActive"
                        :disabled="getFormCondition || $store.state.loading"
                        size="is-medium"
                        type="is-primary"
                        icon-right="arrow-right"
                        :loading="$store.state.loading"
                        @click="nextPage"
                    >
                        <span>{{ $t('next') }}</span>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, numeric, minLength } from 'vuelidate/lib/validators'
import AutoComplete from '@/shared/components/AutoComplete'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import utils from '@/shared/plugins/utils'

export default {
    components: {
        AutoComplete,
    },
    data() {
        return {}
    },
    validations: {
        addressInfo: {
            streetname: {
                required,
            },
            postalcode: {
                required,
                numeric,
                minLength: minLength(4),
            },
            streetnumber: {
                required,
            },
            municipality: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters(['getAddress', 'getFullStore', 'isDraft']),
        ...mapGetters('Address', ['getFeatures']),
        isActive() {
            return this.getAddress.full_address !== ''
        },
        streetname: {
            get() {
                return this.$store.state.Address.address.streetname
            },
            set(value) {
                this.SET_address({ streetname: value })
            },
        },
        streetnumber: {
            get() {
                return this.$store.state.Address.address.streetnumber
            },
            set(value) {
                this.SET_address({ streetnumber: value })
            },
        },
        boxnumber: {
            get() {
                return this.$store.state.Address.address.boxnumber
            },
            set(value) {
                this.SET_address({ boxnumber: value })
            },
        },
        postalcode: {
            get() {
                return this.$store.state.Address.address.postalcode
            },
            set(value) {
                this.SET_address({ postalcode: value })
            },
        },
        municipality: {
            get() {
                return this.$store.state.Address.address.municipality
            },
            set(value) {
                this.SET_address({ municipality: value })
            },
        },
        addressInfo() {
            return this.$store.state.Address.address
        },
        getFormCondition() {
            if (this.$v.addressInfo.$invalid) {
                return true
            }
            return false
        },
    },
    methods: {
        ...mapMutations(['UPDATE_STATUS']),
        ...mapMutations('Address', ['SET_address']),
        ...mapActions('Address', [
            'match_address',
            'fetch_map_data',
            'auto_select_parcels',
        ]),
        setAddress(val) {
            this.SET_address(val)
        },
        nextPage() {
            if (!this.isDraft) {
                this.$router.push({
                    name: 'request',
                    params: {
                        lang: this.$route.params.lang,
                        action: 'view-building-parcels',
                    },
                    query: this.$route.query,
                })
            } else {
                this.UPDATE_STATUS({ loading: true })
                this.match_address()
                    .then(this.fetch_map_data)
                    .then(this.auto_select_parcels)
                    .finally(() => {
                        this.UPDATE_STATUS({ loading: false })
                        this.$axios.patch(
                            utils.val_urls.request_ref(
                                this.$store.state.valuation_request_ref
                            ),
                            {
                                address: {
                                    streetname: this.$store.state.Address.address
                                        .streetname,
                                    streetnumber: this.$store.state.Address.address
                                        .streetnumber,
                                    postalcode: this.$store.state.Address.address
                                        .postalcode,
                                    municipality: this.$store.state.Address.address
                                        .municipality,
                                    boxnumber: this.$store.state.Address.address
                                        .boxnumber,
                                },
                            }
                        )
                        this.$router.push({
                            name: 'request',
                            params: {
                                lang: this.$route.params.lang,
                                action:
                                    this.getFeatures.level < '2'
                                        ? 'select-main-building'
                                        : 'confirm-main-building',
                            },
                            query: this.$route.query,
                        })
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.address-section {
    font-family: Lato;
    h3 {
        font-family: Montserrat;
        font-size: 18px;
        line-height: 22px;
    }
    .label {
        font-size: 14px;
    }
    .field.is-grouped .field:not(:last-child) {
        margin-right: 40px;
    }
}
</style>
