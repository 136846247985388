var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-stepper",class:{ 'pt-7': _vm.$route.params.action }},[(_vm.$route.params.action == 'faq')?_c('div',[_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center px-5"},[_c('b-button',{staticClass:"is-capitalized is-back",attrs:{"icon-left":"arrow-left","type":"is-primary","size":"is-medium","expanded":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1)]):_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-flex-start pl-6"},[(_vm.flow_type !== 'avm_demo')?_c('div',{staticClass:"is-flex is-align-items-center is-icon-box"},[_c('div',{staticClass:"is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center",class:{
                    'has-background-primary-light': _vm.routeChange === 1,
                    'has-background-success-light': _vm.routeChange > 1,
                }},[(_vm.routeChange == 0)?_c('svg-icon',{attrs:{"icon":"location","width":"25","height":"25","color":"#798DA6","fill":false}}):(_vm.routeChange == 1)?_c('svg-icon',{attrs:{"icon":"location","width":"25","height":"25","color":"#0096FF","fill":false}}):_c('svg-icon',{attrs:{"icon":"check","width":"25","height":"25","color":"#52D860"}})],1),_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"pl-4",class:{
                        'has-text-weight-bold has-text-black': _vm.routeChange === 1,
                    }},[_vm._v(" "+_vm._s(_vm.t('customer_ref'))+" ")]),_c('span',{staticClass:"pl-4 has-text-black has-text-weight-semibold"},[_vm._v(" "+_vm._s(this.$store.state.customer_ref || '')+" ")])])]):_vm._e(),_c('div',{staticClass:"is-flex is-align-items-center is-icon-box"},[_c('div',{staticClass:"is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center",class:{
                    'has-background-primary-light': _vm.routeChange === 2,
                    'has-background-success-light': _vm.routeChange > 2,
                }},[(_vm.routeChange < 2)?_c('svg-icon',{attrs:{"icon":"house","width":"25","height":"25","color":"#798DA6","fill":false}}):(_vm.routeChange === 2)?_c('svg-icon',{attrs:{"icon":"house","width":"25","height":"25","color":"#0096FF","fill":false}}):_c('svg-icon',{attrs:{"icon":"check","width":"25","height":"25","color":"#52D860"}})],1),_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"pl-4",class:{
                        'has-text-weight-bold has-text-black': _vm.routeChange === 2,
                    }},[_vm._v(" "+_vm._s(_vm.$t('building_type'))+" ")]),_c('span',{staticClass:"pl-4 has-text-black has-text-weight-semibold"},[_vm._v(" "+_vm._s(this.$store.state.Address.features.f_building_type ? _vm.$t(this.$store.state.Address.features.f_building_type) : ' ')+" ")])])]),_c('div',{staticClass:"is-flex is-align-items-center is-icon-box"},[_c('div',{staticClass:"is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center",class:{
                    'has-background-primary-light': _vm.routeChange === 3,
                    'has-background-success-light': _vm.routeChange > 3,
                }},[(_vm.routeChange < 3)?_c('svg-icon',{attrs:{"icon":"location","width":"25","height":"25","color":"#798DA6","fill":false}}):(_vm.routeChange == 3)?_c('svg-icon',{attrs:{"icon":"location","width":"25","height":"25","color":"#0096FF","fill":false}}):_c('svg-icon',{attrs:{"icon":"check","width":"25","height":"25","color":"#52D860"}})],1),_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('span',{staticClass:"pl-4",class:{
                        'has-text-weight-bold has-text-black': _vm.routeChange === 3,
                    }},[_vm._v(" "+_vm._s(_vm.$t('address_confirmation'))+" ")]),_c('span',{staticClass:"pl-4 has-text-weight-semibold has-text-black"},[_vm._v(" "+_vm._s(_vm.getAddress.short_address)+" ")])])]),_c('div',{staticClass:"is-flex is-align-items-center is-icon-box"},[_c('div',{staticClass:"is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center",class:{
                    'has-background-primary-light': _vm.routeChange === 4,
                    'has-background-success-light': _vm.routeChange > 4,
                }},[(_vm.routeChange < 4)?_c('svg-icon',{attrs:{"icon":"ruler","width":"25","height":"25","color":"#798DA6","fill":false}}):(_vm.routeChange === 4)?_c('svg-icon',{attrs:{"icon":"ruler","width":"25","height":"25","color":"#0096FF","fill":false}}):_c('svg-icon',{attrs:{"icon":"check","width":"25","height":"25","color":"#52D860"}})],1),_c('span',{staticClass:"pl-4",class:{
                    'has-text-weight-bold has-text-black': _vm.routeChange === 4,
                }},[_vm._v(" "+_vm._s(_vm.$t('extra_info'))+" ")])]),_c('div',{staticClass:"is-flex is-align-items-center is-icon-box"},[_c('div',{staticClass:"is-icon-box-wrapper is-flex is-align-items-center is-justify-content-center",class:{
                    'has-background-primary-light': _vm.routeChange === 5,
                    'has-background-success-light': _vm.routeChange > 5,
                }},[_c('b-icon',{attrs:{"type":{
                        'is-primary': _vm.routeChange === 5,
                        'is-success': _vm.routeChange > 5,
                    },"icon":_vm.fifthhStep}})],1),_c('span',{staticClass:"pl-4",class:{
                    'has-text-weight-bold has-text-black': _vm.routeChange === 5,
                }},[_vm._v(" "+_vm._s(_vm.$t('feedback'))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }