<i18n>
{
  "nl-BE": {
    "welcome_title": "Bereken een vastgoedprijs",
    "welcome_text_1": "Welkom! Deze toepassing werkt voor elk huis of appartement met een adres in België.",
    "welcome_text_2": "Elke aanvraag start met het invoeren van de relevante pand informatie.",
    "welcome_text_3": "Dit is een testversie van de Rockestate schattingstoepassing. De configuratie is opgezet op een manier om u toe te laten om een goed idee te vormen over de verschillende waarderingsalternatieven die Rockestate aanbiedt (automatisch, op afstand & ter plaatse). De implementatie van deze applicatie in een productie-omgeving kan licht verschillen van deze testversie.",
    "start_button_text": "Start",
    "dashboard": "Dashboard",
    "argenta": {
        "welcome_text_2": "Elke aanvraag start met het invoeren van de interne referentie van het krediet, zijnde het klantnummer van (één van) de kredietnemer(s). Vervolgens stellen we u enkele vragen over het pand in kwestie. Voor meer informatie, raadpleeg de FAQ.",
        "welcome_text_3": "Deze tool mag enkel gebruikt worden door Argenta-medewerkers die hypothecaire kredieten aanvragen via de applicatie kredietaanvraag. Bovendien mag deze toepassing enkel gebruikt worden in dossiers waarin de klant heeft ingestemd met het indienen van een kredietaanvraag bij Argenta."
    },
    "keytrade": {
        "welcome_text_2": "Elke aanvraag start met het invoeren van de relevante interne referentie van de lening. Vervolgens stellen we u enkele vragen over het pand in kwestie. Voor meer informatie, raadpleeg de FAQ.",
        "welcome_text_3": "Deze tool mag enkel gebruikt worden door Keytrade-medewerkers met een hypothecair brevet en dit voor een reëel dossier met interne referentie."
    },
    "ing": {
        "welcome_text_2": "Elke aanvraag start met het invoeren van de relevante LOP ID. Vervolgens stellen we u enkele vragen over het pand in kwestie. Voor meer informatie, raadpleeg de FAQ.",
        "welcome_text_3": "Deze tool mag enkel gebruikt worden door ING-medewerkers met een hypothecair brevet en dit voor een reëel LOP dossier. De link naar deze tool mag niet doorgestuurd worden, noch binnen noch buiten het ING netwerk. Dit omwille van interne maatregelen tegen fraude of misbruik."
    }
  },
  "en-BE": {   
    "welcome_title": "Calculate a real estate price",
    "welcome_text_1": "Welcome! This application lets you estimate a price for every house or apartment with an address in Belgium.",
    "welcome_text_2": "Every request starts with the input of the relevant property details.",
    "welcome_text_3": "This is a demo version of the Rockestate valuation tool. It is configured in such a way to give you a good idea of how the Rockestate valuation alternatives (automatic, desktop & on-site) could function. Please note an implementation of this tool in a production environment could slightly differ from this demo version.",
    "start_button_text": "Start",
    "dashboard": "Dashboard",
    "argenta": {
        "welcome_text_2": "Every request starts with the input of the internal reference of the loan, being the customer number of (one of) the borrower(s). We then ask you a couple of questions related to the property. For more information, please refer to the FAQ.",
        "welcome_text_3": "This tool may only be used by Argenta personnel who apply for mortgage loans via the loan application. Furthermore, this application may only be used in cases where the customer has agreed to submit a credit application to Argenta."
    },
    "keytrade": {
        "welcome_text_2": "Every request starts with the input of the relevant internal reference of the loan. We then ask you a couple of questions related to the property. For more information, please refer to the FAQ.",
        "welcome_text_3": "This tool may only be used by Keytrade employees with a mortgage license and only for actual internal reference files only."
    },
    "ing": {
        "welcome_text_2": "Every request starts with the input of the relevant LOP ID. We then ask you a couple of question related to the property. For more info, please refer to the FAQ.",
        "welcome_text_3": "This tool may only be used by ING employees with a mortgage license and this for actual LOP files only. The link to this tool may not be forwarded, nor inside nor outside the ING network. This is because of internal measures against fraud or abuse."
    }
  },
  "fr-BE": {   
    "welcome_title": "Calculez un prix immobilier",
    "welcome_text_1": "Bienvenue ! Cette application vous permet d'estimer un prix immobilier pour chaque maison ou appartement en Belgique.",
    "welcome_text_2": "Chaque demande commence avec l'entrée des détails sur un bien.",
    "welcome_text_3": "Ceci est une démo de l'outil de valuation Rockestate. Il est configuré pour vous donner une idée sur les différentes alternatives de valuation de Rockestate (automatique, à distance et sur place). L'implémentation de cet outil dans un environment de production peut varier légèrement de cette version démo.",
    "start_button_text": "Commencer",
    "dashboard": "Dashboard",
    "argenta": {
        "welcome_text_2": "Chaque demande commence par la saisie de la référence interne du prêt immobilier, c'est-à-dire le numéro de client de (l'un des) l'emprunteur(s). Ensuite, nous vous posons quelques questions sur le bien en question. Pour plus d'informations, veuillez consulter la FAQ.",
        "welcome_text_3": "Cet outil n'est accessible que pour le personnel d'Argenta lors d'une demande de prêt hypothécaire via l'application de demande de crédit. En outre, cette application ne peut être utilisée que dans les cas où le client a accepté de soumettre une demande de crédit à Argenta."
    },
    "keytrade": {
        "welcome_text_2": "Chaque demande commence par l'introduction de la référence interne liée au prêt. Ensuite, Rock.estate vous demandes quelques questions relatives au bien. Pour plus d'informations, veuillez vous référer à la FAQ.",
        "welcome_text_3": "Cet outil ne peut être utilisé que par les employés de Keytrade disposant d'une licence hypothécaire et uniquement pour des fichiers de référence internes réels."
    },
    "ing": {
        "welcome_text_2": "Chaque demande commence avec l'entrée d'un LOP ID. Nous vous posons ensuite quelques questions concernant le bien en question. Pour plus d'informations, consultez la FAQ.",
        "welcome_text_3": "Cet outil ne peut être utilisé que par les employées ING munis d'une licence hypothécaire et ce uniquement pour un dossier LOP réel. Le lien vers cet outil ne peut être transmis, ni à l'intérieur ni à l'extérieur du réseau ING. Ceci est dû aux mesures internes contre la fraude ou l'abus."
    }
  }
}
</i18n>

<template>
    <div class="container section is-fluid">
        <!-- we need bg image here -->
        <h1 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            {{ t('welcome_title') }}
        </h1>
        <div class="columns is-mobile">
            <div class="column is-8-desktop is-10-tablet is-12-mobile">
                <p class="has-text-weight-normal">
                    {{ t('welcome_text_1') }}
                </p>
                <p class="has-text-weight-normal my-5">
                    {{ t('welcome_text_2') }}
                </p>
                <p class="has-text-weight-normal my-5">
                    {{ t('welcome_text_3') }}
                </p>
            </div>
        </div>

        <div class="column is-9-fullhd is-10-tablet is-12-mobile">
            <div class="is-flex is-justify-content-space-between">
                <b-button
                    class="mt-1"
                    size="is-medium"
                    type="is-dark"
                    icon-left="view-dashboard"
                    tag="router-link"
                    :to="{
                        name: 'dashboard',
                        params: {
                            lang: $route.params.lang,
                        },
                    }"
                >
                    <span>{{ t('dashboard') }}</span>
                </b-button>
                <b-button
                    class="mt-1"
                    size="is-medium"
                    type="is-primary"
                    icon-right="arrow-right"
                    @click="nextStep"
                >
                    <span>{{ t('start_button_text') }}</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'

export default {
    name: 'Start',
    mixins: [localeMixin],
    computed: {
        ...mapGetters(['flow_type', 'getFullStore']),
    },
    methods: {
        ...mapActions(['create_valuation_request']),
        ...mapMutations(['SET_CUSTOMER_REF']),
        // if not promise generateRandomId is undefined
        generateRandomId() {
            return new Promise((resolve) => {
                const randNumber = Math.random()
                    .toString(36)
                    .substring(2)
                this.SET_CUSTOMER_REF(randNumber)
                this.create_valuation_request(randNumber, true).then(() => {
                    resolve(true)
                })
            })
        },
        nextStep() {
            if (this.flow_type === 'avm_demo') {
                this.generateRandomId().then(() => {
                    this.$router.push({
                        name: 'request',
                        params: { lang: this.$i18n.locale, action: 'building-type' },
                        query: {
                            valuation_request_ref: this.getFullStore
                                .valuation_request_ref,
                        },
                    })
                })
            } else {
                this.$router.push({
                    name: 'request',
                    params: { lang: this.$i18n.locale, action: 'enter' },
                })
            }
        },
    },
}
</script>
