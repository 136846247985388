<template>
    <div class="container is-fluid section is-max-desktop">
        <div class="columns is-mobile is-multiline is-variable is-6">
            <div class="column is-12">
                <h2
                    class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('renovation_info.title') }}
                </h2>
            </div>

            <div
                v-for="category in Object.keys(category_subcategory)"
                :key="category"
                class="column is-12"
            >
                <b-collapse class="card">
                    <template #trigger="props">
                        <div class="card-header content level" role="button">
                            <span
                                class="card-header-title level-left icon-text noselect has-text-weight-bold is-size-5"
                            >
                                {{ $t(`renovation_category.${category}`) }}
                            </span>
                            <span class="level-right card-header-icon has-text-black">
                                <span class="mr-4">
                                    {{ $t('common.total') }}:
                                    {{
                                        exact_value_formatter(
                                            Object.values(renovation_items)
                                                .filter(
                                                    (item) => item.category === category
                                                )
                                                .map((item) => item.amount)
                                                .reduce((a, b) => a + b, 0)
                                        )
                                    }}
                                </span>

                                <b-icon
                                    :icon="props.open ? 'menu-down' : 'menu-up'"
                                ></b-icon>
                            </span>
                        </div>
                    </template>
                    <div class="card-content">
                        <div
                            v-for="subcategory in category_subcategory[category]"
                            :key="`${category}_${subcategory}`"
                            class="content card"
                        >
                            <div class="card-header content level">
                                <span
                                    class="card-header-title level-left icon-text noselect has-text-weight-bold is-size-6"
                                >
                                    {{ $t(`renovation_subcategory.${subcategory}`) }}
                                </span>
                                <span class="level-right card-header-icon">
                                    <b-button
                                        type="is-primary"
                                        outlined
                                        class="is-small"
                                        icon-right="plus"
                                        @click="
                                            edit_renovation_item({
                                                category: category,
                                                subcategory: subcategory,
                                            })
                                        "
                                    />
                                </span>
                            </div>
                            <div
                                v-if="
                                    category_subcategory_items[category][subcategory]
                                        .length > 0
                                "
                                class="card-content"
                            >
                                <div
                                    v-for="renovation_item in category_subcategory_items[
                                        category
                                    ][subcategory]"
                                    :key="renovation_item.renovation_item_ref"
                                    class="level content"
                                >
                                    <span class="level-left has-text-black">
                                        {{ renovation_item.title }}
                                    </span>
                                    <b-button
                                        v-if="
                                            renovation_item.linked_document_ref === null
                                        "
                                        outlined
                                        icon-right="link"
                                        type="is-primary"
                                        class="is-small"
                                        @click="edit_renovation_item(renovation_item)"
                                    >
                                        {{ $t('renovation_info.link_document') }}
                                    </b-button>
                                    <span v-else>
                                        <b-button
                                            tag="a"
                                            outlined
                                            icon-right="eye"
                                            type="is-primary"
                                            class="is-small"
                                            :href="
                                                documentURL(
                                                    renovation_item.linked_document_ref
                                                )
                                            "
                                        >
                                            {{
                                                documents[
                                                    renovation_item.linked_document_ref
                                                ].filename
                                            }}
                                        </b-button>
                                    </span>
                                    <span class="level-right">
                                        <span class="has-text-black mr-2">
                                            {{
                                                exact_value_formatter(
                                                    renovation_item.amount
                                                )
                                            }}
                                        </span>
                                        <b-button
                                            type="is-primary"
                                            icon-right="pencil"
                                            outlined
                                            class="is-small"
                                            @click="edit_renovation_item(renovation_item)"
                                        />
                                        <b-button
                                            type="is-primary"
                                            icon-right="delete"
                                            outlined
                                            class="is-small"
                                            @click="
                                                delete_renovation_item(renovation_item)
                                            "
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <b-button
            size="is-medium"
            type="is-dark"
            icon-left="arrow-left"
            @click="prev_page"
        >
            {{ $t('common.previous') }}
        </b-button>
        <b-modal
            v-model="edit_renovation_modal_active"
            trap-focus
            aria-role="dialog"
            aria-modal
        >
            <header class="modal-card-head">
                <p class="modal-card-title">{{ $t('renovation_item.modal_title') }}</p>
                <button type="button" class="delete" @click="close_renovation_modal()" />
            </header>
            <section class="modal-card-body">
                <text-input
                    v-model="current_renovation_item.title"
                    :label="$t('renovation_item.title')"
                    @input="current_renovation_item.dirty = true"
                />
                <b-field>
                    <template slot="label">
                        {{ $t('renovation_item.amount') }}
                    </template>
                    <b-input
                        v-model="current_renovation_item.amount"
                        size="is-medium"
                        :disabled="!isDraft"
                        expanded
                        @input="current_renovation_item.dirty = true"
                    ></b-input>
                </b-field>

                <b-dropdown
                    v-if="isDraft"
                    v-model="current_renovation_item.linked_document_ref"
                    :disabled="current_renovation_item.uploading"
                    class="my-6 is-w100"
                >
                    <template #trigger>
                        <div
                            v-if="current_renovation_item.linked_document_ref === null"
                            class="media button is-primary is-outlined"
                        >
                            <b-icon class="file-icon" icon="link"></b-icon>
                            <div class="media-content">
                                <span>
                                    {{ $t('renovation_info.link_document') }}
                                </span>
                            </div>
                        </div>
                        <span
                            v-else-if="
                                current_renovation_item.linked_document_ref === 'new'
                            "
                        >
                            {{ $t('common.uploading') }}
                        </span>
                        <div v-else class="button is-primary is-outlined">
                            <b-icon class="file-icon" icon="chevron-down"></b-icon>
                            <span>
                                {{
                                    documents[current_renovation_item.linked_document_ref]
                                        .filename
                                }}
                            </span>
                        </div>
                    </template>
                    <b-dropdown-item :value="null" aria-role="listitem">
                        <div class="media">
                            <b-icon class="file-icon" icon="link-off"></b-icon>
                            <div class="media-content">
                                <small>{{ $t('renovation_info.unlink_document') }}</small>
                            </div>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-for="document in Object.values(documents).filter(
                            (doc) => doc.purpose === document_purpose
                        )"
                        :key="document.document_ref"
                        :value="document.document_ref"
                        aria-role="listitem"
                    >
                        <div class="media">
                            <b-icon class="file-icon" icon="file"></b-icon>
                            <div class="media-content">
                                <small>{{ document.filename }}</small>
                            </div>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item :value="'new'" aria-role="listitem">
                        <b-upload
                            v-model="pending_document"
                            class="file-upload is-expanded"
                            outlined
                            accept=".jpg,.jpeg,.png,.pdf,image/png,image/jpeg,application/pdf"
                            @input="upload_document"
                        >
                            <a class="button is-primary is-fullwidth">
                                <b-icon class="file-icon" icon="upload" />
                                <span class="file-label mr-1">
                                    {{ $t(`renovation_info.add_document`) }}
                                </span>
                            </a>
                        </b-upload>
                    </b-dropdown-item>
                </b-dropdown>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    :label="$t('common.cancel')"
                    type="is-primary"
                    outlined
                    @click="close_renovation_modal()"
                />
                <b-button
                    :label="$t('common.save')"
                    type="is-primary"
                    @click="save_renovation_item()"
                />
            </footer>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {
    required,
    requiredIf,
    decimal,
    minValue,
    maxValue,
} from 'vuelidate/lib/validators'
import TextInput from '../elements/TextInput.vue'
import utils from '@/shared/plugins/utils'

const minYear = 1000
const maxYear = new Date().getFullYear() + 1
const document_purpose = 'renovation_quotation'
function default_data() {
    return {
        document_purpose,
        edit_renovation_modal_active: false,
        documents: {},
        renovation_items: {},
        category_subcategory: {
            energy: ['insulation', 'solar_panel', 'heating', 'other'],
            structural: ['wall_extension', 'demolition', 'other'],
            aesthetic: ['kitchen', 'bathroom', 'other'],
            other: ['other'],
        },
        current_renovation_item: {
            index: null,
            renovation_item_ref: null,
            title: null,
            amount: null,
            category: null,
            subcategory: null,
            linked_document_ref: null,
            dirty: false,
        },
        pending_document: null,
    }
}
export default {
    components: {
        TextInput,
    },
    data: () => ({
        ...default_data(),
    }),
    computed: {
        ...mapGetters('Address', ['getFeatures', 'getFullBuildingData']),
        ...mapGetters(['getFullStore', 'isDraft', 'flow_type', 'getRequestRef']),
        isDocWrongFormat() {
            return ['image/png', 'image/jpeg', 'application/pdf'].includes(
                this.pending_document.type
            )
        },
        category_subcategory_items() {
            let csi = {}
            console.log(this.category_subcategory)
            for (let category in this.category_subcategory) {
                csi[category] = {}
                for (let subcategory of this.category_subcategory[category]) {
                    csi[category][subcategory] = Object.values(
                        this.renovation_items
                    ).filter(
                        (item) =>
                            item.category === category && item.subcategory === subcategory
                    )
                }
            }
            console.log(csi)
            return csi
        },
    },
    watch: {},
    mounted() {
        this.$axios
            .get(
                utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                    '/request',
                    this.getRequestRef,
                    '/documents',
                ])
            )
            .then((res) => {
                this.documents = {}
                for (let document of res.data) {
                    this.$set(this.documents, document.document_ref, document)
                }
            })
        this.$axios
            .get(
                utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                    '/request',
                    this.getRequestRef,
                    '/renovation-items',
                ])
            )
            .then((res) => {
                this.renovation_items = {}
                for (let renovation_item of res.data) {
                    this.$set(
                        this.renovation_items,
                        renovation_item.renovation_item_ref,
                        renovation_item
                    )
                }
            })
    },
    methods: {
        ...mapMutations(['UPDATE_STATUS']),
        ...mapMutations('Address', ['SET_FEATURES']),
        ...mapActions(['updateStore']),
        prev_page() {
            this.$router.push({
                params: {
                    lang: this.$route.params.lang,
                    action: 'extra-info',
                },
                query: this.$route.query,
            })
        },
        edit_renovation_item(renovation_item) {
            this.current_renovation_item = {
                ...default_data().current_renovation_item,
                ...renovation_item,
            }
            this.edit_renovation_modal_active = true
        },
        delete_renovation_item(renovation_item) {
            this.$axios
                .delete(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        '/renovation-item',
                        renovation_item.renovation_item_ref,
                    ])
                )
                .then((response) => {
                    this.$delete(
                        this.renovation_items,
                        renovation_item.renovation_item_ref
                    )
                })
        },
        save_renovation_item() {
            let method
            let url
            if (this.current_renovation_item.renovation_item_ref) {
                method = 'patch'
                url = utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                    '/renovation-item',
                    this.current_renovation_item.renovation_item_ref,
                ])
            } else {
                method = 'post'
                url = utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                    '/request',
                    this.getRequestRef,
                    '/renovation-item',
                ])
            }
            this.$axios({
                method: method,
                url: url,
                data: this.current_renovation_item,
            }).then((response) => {
                let renovation_item = response.data
                this.$set(
                    this.renovation_items,
                    renovation_item.renovation_item_ref,
                    renovation_item
                )
                this.edit_renovation_modal_active = false
            })
        },
        close_renovation_modal() {
            // TODO: dirty modal check
            this.edit_renovation_modal_active = false
            this.current_renovation_item = default_data().current_renovation_item
        },
        upload_document() {
            this.current_renovation_item.linked_document_ref = 'new'
            let formData = new FormData()
            formData.append('attachment', this.pending_document)
            this.current_renovation_item.uploading = true
            this.$axios
                .post(
                    utils.urlJoin(process.env.VUE_APP_VALUATION_API_URL, [
                        '/request',
                        this.getRequestRef,
                        '/documents',
                    ]),
                    formData,
                    { params: { purpose: document_purpose } }
                )
                .then((response) => {
                    for (let document of response.data) {
                        this.$set(this.documents, document.document_ref, document)
                        this.current_renovation_item.linked_document_ref =
                            document.document_ref
                    }
                    this.current_renovation_item.uploading = false
                })
        },
        documentURL(document_ref) {
            return utils.urlJoin(process.env.VUE_APP_API_URL || '', [
                process.env.VUE_APP_VALUATION_API_URL,
                'document',
                document_ref,
            ])
        },
        exact_value_formatter: utils.exact_value_formatter,
    },
}
</script>

<style scoped lang="scss">
@import '@/shared/assets/style/buefy.scss';
@import '~bulma/sass/utilities/functions';

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.is-small-tooltip {
    font-size: 14px;
    .info {
        height: 20px;
        width: 20px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.is-tootip-btn {
    height: 24px;
    width: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.is-input-number {
    .icons {
        width: 40px;
        font-size: 20px;
        div {
            height: 25px;
            &:first-child {
                border-top-right-radius: 4px;
                border-top: 1px solid;
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
            }
            &:last-child {
                border-bottom: 1px solid;
                border-right: 1px solid;
                border-color: #e5eaed;
                border-bottom-right-radius: 4px;
            }
        }
    }
}
.is-modal-message {
    max-width: 400px;
    display: inline-block;
}
.media {
    align-items: center !important;
}
.animation-content {
    z-index: 98;
}

.is-uploading-icon {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.is-unit {
    height: auto;
    position: relative;
    bottom: 0.4rem;
}

.is-w100 {
    width: 100%;
}
</style>
