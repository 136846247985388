/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'heating-fuel-coal': {
    width: 38,
    height: 37,
    viewBox: '0 0 38 37',
    data: '<svg><path d="M38 12.6149L32.458 4.62599L23.2762 0L7.12538 7.31864L0 18.5L2.51362 24.7164L2.53739 29.9816L7.86558 32.9145L13.7678 33.1547L20.8932 37L32.6975 33.4763L35.6506 28.3389L32.7806 22.7211L38 12.6149ZM30.5896 5.59458L35.5933 12.1035L29.2596 10.9876L25.4395 7.90949L24.4044 2.4331L30.5896 5.59458ZM14.5239 16.0708L15.8342 9.64519L17.0376 16.4427L14.5239 16.0708ZM22.3143 2.4331L22.6112 4.76934L17.2058 5.11993L22.3143 2.4331ZM33.3764 27.4555L24.5944 28.2304L18.1143 17.8568L25.206 24.2825L30.6114 23.0427L33.3764 27.4555Z"/></svg>'
  }
})
