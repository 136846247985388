/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<svg><path d="M22.6525 0.732233C21.6762 -0.244078 20.0933 -0.244078 19.117 0.732233L11.6916 8.15767L4.26802 0.734116C3.29171 -0.242195 1.70879 -0.242195 0.732484 0.734116C-0.243827 1.71043 -0.243827 3.29334 0.732484 4.26965L8.15604 11.6932L0.732233 19.117C-0.244078 20.0933 -0.244078 21.6762 0.732233 22.6525C1.70854 23.6289 3.29146 23.6289 4.26777 22.6525L11.6916 15.2287L19.1173 22.6544C20.0936 23.6307 21.6765 23.6307 22.6528 22.6544C23.6291 21.6781 23.6291 20.0952 22.6528 19.1189L15.2271 11.6932L22.6525 4.26777C23.6289 3.29146 23.6289 1.70854 22.6525 0.732233Z"/></svg>'
  }
})
