<i18n>
{
  "nl-BE": {
    "previous": "Vorige",
    "next": "Volgende",
    "yes": "Ja",
    "confirm": "Bevestig",
    "explanation-confirm-main-building": "Is dit het juiste gebouw??",
    "cannot-find-confirm-main-building": "Dit is niet het juiste gebouw",
    "tooltip-for-confirm-main-building": "Als dit niet het juiste gebouw is, kunt u op de onderstaande link klikken om een ander gebouw te kiezen",
    "explanation-select-main-building": "Selecteer het hoofdgebouw",
    "cannot-find-select-main-building": "Ik kan het hoofdgebouw niet vinden",
    "tooltip-for-select-main-building": "Klik met de muis op het hoofdgebouw verbonden aan het door u opgegeven adres",
    "explanation-select-secondary-buildings": "Selecteer het/de bijgebouw(en)",
    "cannot-find-select-secondary-buildings": "Ik kan sommige bijgebouwen niet vinden",
    "tooltip-for-select-secondary-buildings": "",
    "explanation-select-parcels": "Selecteer percelen",
    "cannot-find-select-parcels": "Ik kan sommige percelen niet vinden",
    "tooltip-for-select-parcels": "Klik met de muis op de percelen verbonden aan de woning",
    "explanation-view-building-parcels": "Deze gebouw en percelen werden geselecteerd",
    "tooltip-for-view-building-parcels": "Indien u deze gegevens wenst te wijzigen, dient u deze aanvraag te annuleren en een nieuwe aanvraag in te dienen",
    "address": "adres",
    "full_address": "Het gebouw op het opgegeven adres kan niet automatisch gevonden worden",
    "message": "Message",
    "mutliple_addresses_modal": {
        "title": "Multiple addresses for this building",
        "content": "Please select the appropriate one",
        "cancel": "Cancel"
    }
  },
  "en-BE": {
    "previous": "Previous",
    "next": "Next",
    "yes": "Yes",
    "confirm": "Confirm",
    "explanation-confirm-main-building": "Is this the right building?",
    "cannot-find-confirm-main-building": "This is not the right building",
    "tooltip-for-confirm-main-building": "If this is not the right building, you can click on the link below to be able to select a different one",
    "explanation-select-main-building": "Select the main building",
    "cannot-find-select-main-building": "I cannot find the main building",
    "tooltip-for-select-main-building": "Click with the mouse on the main building associated with the address you entered",
    "explanation-select-secondary-buildings": "Select secondary building(s)",
    "cannot-find-select-secondary-buildings": "I cannot find some secondary building(s)",
    "tooltip-for-select-secondary-buildings": "",
    "explanation-select-parcels": "Select parcels",
    "cannot-find-select-parcels": "I cannot find some parcels",
    "tooltip-for-select-parcels": "Click with the mouse on the parcels associated with the house",
    "explanation-view-building-parcels": "These building and parcels were selected",
    "tooltip-for-view-building-parcels": "If you wish to change this information, you must cancel this request and create a new one",
    "address": "address",
    "full_address": "The building corresponding to the address cannot be found automatically",
    "message": "Message",
    "mutliple_addresses_modal": {
        "title": "Multiple addresses for this building",
        "content": "Please select the appropriate one",
        "cancel": "Cancel"
    }
  },
  "fr-BE": {
    "previous": "Précédent",
    "next": "Suivant",
    "yes": "Oui",
    "confirm": "Confirmer",
    "explanation-confirm-main-building": "Est-ce bien le bon bâtiment?",
    "cannot-find-confirm-main-building": "Ceci n'est pas le bon bâtiment",
    "tooltip-for-confirm-main-building": "Si ce n'est pas le bon bâtiment, vous pouvez cliquer sur le lien ci-dessous pour pouvoir en sélectionner un autre",
    "explanation-select-main-building": "Sélectionnez le bâtiment principal",
    "cannot-find-select-main-building": "Je ne trouve pas le bâtiment principal",
    "tooltip-for-select-main-building": "Cliquez sur le bâtiment principal",
    "explanation-select-secondary-buildings": "Sélectionnez le(s) bâtiment(s) secondaire(s)",
    "cannot-find-select-secondary-buildings": "Je ne trouve pas certains bâtiments secondaires",
    "tooltip-for-select-secondary-buildings": "",
    "explanation-select-parcels": "Sélectionnez les parcelles",
    "cannot-find-select-parcels": "Je ne trouve pas certaines parcelles",
    "tooltip-for-select-parcels": "Cliquer sur la/les parcelle(s) concerncées par le bien",
    "explanation-view-building-parcels": "Ces bâtiment et parcelles ont été sélectionnés",
    "tooltip-for-view-building-parcels": "Si vous désirez changer ces informations, vous devez annuler cette demande et en créer une nouvelle",
    "address": "adresse",
    "full_address": "Le bâtiment correspondant à l'adresse n'a pas été trouvé automatiquement",
    "building_tooltip": "Cliquez sur le bâtiment principal",
    "parcel_tooltip": "Cliquer sur la/les parcelle(s) concerncées par le bien",
    "message": "Message",
    "mutliple_addresses_modal": {
        "title": "Multiple addresses for this building",
        "content": "Please select the appropriate one",
        "cancel": "Cancel"
    }
  }
}
</i18n>
<template>
    <div class="address_map is-relative">
        <b-loading
            v-model="isLoading"
            :is-full-page="false"
            :can-cancel="false"
        ></b-loading>
        <div v-if="noBuildingMatch" class="is-user-message">
            <b-message
                :active="noBuildingMatch"
                :title="$t('message')"
                type="is-info"
                has-icon
                aria-close-label="Close message"
            >
                <span class="is-flex is-flex-direction-column">
                    <span>
                        <strong class="is-capitalized">
                            {{ $t('address') }}
                        </strong>
                        {{ getAddress.full_address }}
                    </span>
                    <span>{{ $t('full_address') }}</span>
                </span>
            </b-message>
        </div>
        <re-map
            :building-id="getFeatures.building_id"
            :parcel-ids="getFeatures.parcel_ids"
            :level="getFeatures.level"
            :region="getFeatures.region"
            :center="{ lat: getFeatures.f_lat, lng: getFeatures.f_lng }"
            :map-scene2d="get_map_scene2d"
            :read-only="!isDraft || $route.params.action === 'confirm-main-building'"
            :focus-layer="focusLayer"
            :layers-control-visible="false"
            :flood-visible="false"
            @update:mapScene2d="SET_MAP_SCENE2D($event)"
            @update:buildingId="update_buildingid_and_address"
            @update:parcelIds="SET_PARCEL_IDS($event)"
        ></re-map>
        <div class="card-wrapper is-absolute">
            <div class="card">
                <div class="card-content">
                    <div class="is-flex is-justify-content-center">
                        <b-button
                            tag="router-link"
                            size="is-medium"
                            type="is-dark"
                            icon-left="arrow-left"
                            :to="{
                                name: 'request',
                                params: {
                                    lang: $route.params.lang,
                                    action: prev_action,
                                },
                                query: $route.query,
                            }"
                        >
                            <span>{{ $t('previous') }}</span>
                        </b-button>
                        <div
                            v-if="$te('explanation-' + $route.params.action)"
                            class="px-4 has-text-black has-text-centered is-w100"
                        >
                            {{ $t('explanation-' + $route.params.action) }}
                            <b-tooltip
                                size="is-medium"
                                class="pl-3 is-clickable is-relative"
                                :label="tooltip_label"
                                multilined
                            >
                                <div
                                    class="is-rounded has-text-primary has-background-link is-tootip-btn is-flex is-align-items-center is-justify-content-center has-text-weight-bold"
                                >
                                    ?
                                </div>
                            </b-tooltip>
                        </div>
                        <b-button
                            :disabled="!next_possible"
                            size="is-medium"
                            type="is-primary"
                            icon-right="arrow-right"
                            @click="nextPage"
                        >
                            <span>
                                {{
                                    next_action === 'extra-info' && isDraft
                                        ? $t('confirm')
                                        : $route.params.action === 'confirm-main-building'
                                        ? $t('yes')
                                        : $t('next')
                                }}
                            </span>
                        </b-button>
                    </div>
                </div>
                <footer
                    v-if="$te('cannot-find-' + $route.params.action)"
                    class="card-footer"
                >
                    <div class="is-flex is-justify-content-center is-w100">
                        <a
                            class="has-text-primary has-text-centered py-3"
                            @click="unhappyFlow"
                        >
                            {{ $t('cannot-find-' + $route.params.action) }}
                        </a>
                    </div>
                </footer>
            </div>
        </div>

        <b-modal
            v-model="isAddressesModalActive"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            class="modal"
            :on-cancel="cancelMultipleAddresses"
            @click="
                () => {
                    return
                }
            "
        >
            <div v-if="isAddressesModalActive" class="exit-modal">
                <h2
                    class="title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                >
                    {{ $t('mutliple_addresses_modal.title') }}
                </h2>
                <p class="mb-1">{{ $t('mutliple_addresses_modal.content') }}</p>
                <b-button
                    v-for="el in buildingAddresses"
                    :key="
                        `${el.properties.building_id}${el.properties.streetname}${el.properties.streetnumber}${el.properties.language}`
                    "
                    class="button is-ghost ghost-button"
                    type="is-ghost"
                    @click="() => setAddress(el)"
                >
                    {{ el.properties.streetname }} {{ el.properties.streetnumber }}
                </b-button>
                <b-button
                    size="is-medium"
                    type="is-dark"
                    class="mt-2"
                    @click="cancelMultipleAddresses"
                >
                    {{ $t('mutliple_addresses_modal.cancel') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import ReMap from '@/components/common/Map.vue'
import utils from '@/shared/plugins/utils'

import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    components: { ReMap },
    data() {
        return {
            isAddressesModalActive: false,
            buildingAddresses: [],
            isLoading: true,
            height: 400,
            map_zoom: 18,
            active: true,
            tile_url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}`,
        }
    },
    computed: {
        ...mapGetters('Address', ['getFeatures', 'get_map_scene2d']),
        ...mapGetters(['getAddress', 'getFullStore', 'isDraft']),
        // get map  init. zoom level with condiont
        noBuildingMatch() {
            return this.getFeatures.level < '2'
        },
        map_data_ready() {
            return this.get_map_scene2d.map_data_ready
        },
        next_possible() {
            switch (this.$route.params.action) {
                case 'select-parcels':
                    return (
                        (this.getFeatures.parcel_ids.length >= 1 &&
                            !this.$store.state.loading) ||
                        !this.isDraft
                    )
                case 'select-main-building':
                    return (
                        (this.map_data_ready &&
                            this.$store.state.Address.features.building_id !== null &&
                            this.$store.state.Address.features.building_ids.length >= 1 &&
                            !this.$store.state.loading) ||
                        !this.isDraft
                    )
                case 'confirm-main-building':
                    return (
                        (this.map_data_ready && !this.$store.state.loading) ||
                        !this.isDraft
                    )
                default:
                    return true
            }
        },
        tooltip_label() {
            return this.$t('tooltip-for-' + this.$route.params.action)
        },
        action_order() {
            let actions = ['address']
            if (!this.isDraft) {
                actions.push('view-building-parcels')
            } else {
                if (
                    !this.noBuildingMatch ||
                    this.$route.params.action === 'select-main-building'
                ) {
                    actions.push('confirm-main-building')
                }
                if (
                    this.noBuildingMatch ||
                    this.$route.params.action === 'select-main-building'
                ) {
                    actions.push('select-main-building')
                }
                if (this.$store.state.Address.features.f_building_type === 'house') {
                    actions.push('select-parcels')
                }
            }
            actions.push('extra-info')
            return actions
        },
        next_action() {
            return this.action_order[
                this.action_order.indexOf(this.$route.params.action) + 1
            ]
        },
        prev_action() {
            return this.action_order[
                Math.max(1, this.action_order.indexOf(this.$route.params.action)) - 1
            ]
        },
        focus() {
            return {
                'select-main-building': 'main-building',
                'confirm-main-building': 'main-building',
                'select-parcels': 'parcels',
                'view-building': 'main-building',
                'view-parcels': 'parcels',
            }[this.$route.params.action]
        },
        focusLayer() {
            if (this.$route.params.action === 'select-parcels') {
                return 'parcels'
            } else {
                return 'building'
            }
        },
    },
    watch: {
        building_id() {
            this.update_map()
        },
        map_data_ready() {
            this.isLoading = !this.map_data_ready
        },
    },
    mounted() {
        if (!this.map_data_ready) {
            this.fetch_map_data()
        }
        this.isLoading = !this.map_data_ready
    },
    methods: {
        ...mapMutations(['UPDATE_STATUS']),
        ...mapMutations('Address', [
            'TOGGLE_SECONDARY_BUILDING_ID',
            'SELECT_MAIN_BUILDING_ID',
            'TOGGLE_PARCEL',
            'SET_PARCEL_IDS',
            'SET_FEATURES',
            'SET_MAP_SCENE2D',
            'SET_address',
        ]),
        ...mapActions('Address', [
            'fetch_features',
            'fetch_map_data',
            'auto_select_parcels',
        ]),
        nextPage() {
            let route_next = () => {
                if (this.next_action === 'extra-info') {
                    if (this.isDraft) {
                        this.SET_FEATURES({
                            buildingnotfound: false,
                            parcelnotfound: false,
                        })
                        this.update_valuation_request()
                    }
                } else {
                    this.UPDATE_STATUS({ loading: false })
                }
                this.$router.push({
                    name: 'request',
                    params: {
                        lang: this.$route.params.lang,
                        action: this.next_action,
                    },
                    query: this.$route.query,
                })
            }
            this.UPDATE_STATUS({ loading: true })
            if (
                (this.$route.params.action === 'select-main-building' ||
                    this.$route.params.action === 'confirm-main-building') &&
                this.isDraft
            ) {
                this.fetch_features().then(route_next)
            } else {
                route_next()
            }
        },
        update_valuation_request() {
            return this.$axios
                .patch(
                    utils.val_urls.request_ref(this.$store.state.valuation_request_ref),
                    {
                        features: {
                            building_id: this.$store.state.Address.features.building_id,
                            parcel_ids: this.$store.state.Address.features.parcel_ids,
                            buildingnotfound: this.$store.state.Address.features
                                .buildingnotfound,
                            parcelnotfound: this.$store.state.Address.features
                                .parcelnotfound,
                        },
                    }
                )
                .then((response) => {
                    this.UPDATE_STATUS({ loading: false })
                })
        },
        main(building_id) {
            return (
                this.$store.state.Address.features.building_ids.length === 1 &&
                this.$store.state.Address.features.building_ids[0] === building_id
            )
        },
        secondary(building_id) {
            return (
                this.$store.state.Address.features.secondary_building_ids.indexOf(
                    building_id
                ) != -1
            )
        },
        selected_parcel(parcel_id) {
            return this.$store.state.Address.features.parcel_ids.indexOf(parcel_id) != -1
        },
        unhappyFlow() {
            switch (this.$route.params.action) {
                case 'confirm-main-building':
                    this.$router.push({
                        name: 'request',
                        params: {
                            lang: this.$route.params.lang,
                            action: 'select-main-building',
                        },
                        query: this.$route.query,
                    })
                    break
                case 'select-main-building':
                    this.SET_FEATURES({
                        buildingnotfound: true,
                    })
                    this.update_valuation_request()
                    this.$router.push({
                        name: 'request',
                        params: {
                            lang: this.$route.params.lang,
                            action: 'extra-info',
                        },
                        query: this.$route.query,
                    })
                    break
                case 'select-parcels':
                    this.SET_FEATURES({
                        parcelnotfound: true,
                    })
                    this.update_valuation_request()
                    this.$router.push({
                        name: 'request',
                        params: {
                            lang: this.$route.params.lang,
                            action: 'extra-info',
                        },
                        query: this.$route.query,
                    })
                    break
            }
        },
        update_map() {
            this.fetch_map_data().then(() => {
                if (this.isDraft) {
                    this.auto_select_parcels()
                }
                if (typeof this.$refs.parcel_features !== 'undefined') {
                    this.$refs.parcel_features.mapObject.eachLayer((layer) => {
                        this.$refs.parcel_features.mapObject.resetStyle(layer)
                    })
                }
            })
        },
        update_buildingid_and_address(id) {
            // FIND address(es) linked to the selected building
            var newBuildingIds = this.get_map_scene2d.address_geojson.features.filter(
                (el) =>
                    el.properties.building_id === id && !el.properties.duplicate_location
            )
            if (newBuildingIds.length === 1) {
                // 1 address for the building
                // => UPDATE ADDRESS AND BUILDING_ID
                this.SELECT_MAIN_BUILDING_ID(id)
                this.SET_address(newBuildingIds[0].properties)
            } else if (newBuildingIds.length > 1) {
                // ++ addresses for the building
                // SHOW "Multiple Addresses for this building" modal + ask to select one
                this.buildingAddresses = newBuildingIds
                this.isAddressesModalActive = true
            } else {
                this.SELECT_MAIN_BUILDING_ID(id)
                // 0 address for that building
                // => SHOW "No address" + ask to fill address form again
                // (Pre-filled with data they entered on the previous screen? Possible to get that info back
                // even if we clicked on another building before this one?)
                // If not : pre-filled with the current selected address
            }
        },
        setAddress(address) {
            this.SELECT_MAIN_BUILDING_ID(address.properties.building_id)
            this.SET_address(address.properties)
            this.buildingAddresses = []
            this.isAddressesModalActive = false
        },
        cancelMultipleAddresses(address) {
            this.buildingAddresses = []
            this.isAddressesModalActive = false
        },
    },
}
</script>

<style lang="scss">
.b-tooltips {
    .b-tooltip:not(:last-child) {
        margin-right: 0.5em;
    }
    .b-tooltip {
        margin-bottom: 0.5em;
    }
}
.leaflet-tooltip.address-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
}

.address_map {
    height: calc(100vh - 53px);
    min-height: calc(100vh - 53px);
    .card-wrapper {
        max-width: 700px;
        z-index: 999;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        .card {
            overflow: visible !important;
        }
        .button {
            min-width: 140px;
        }
        footer a {
            text-decoration: underline;
        }
    }
}
.is-user-message {
    position: fixed;
    top: 100px;
    right: 20px;
    max-width: 500px;
    z-index: 9998;
}
.modal {
    z-index: 9999 !important;
}
.ghost-button {
    margin: 0.1rem 0;
    &:not(:hover) {
        border-color: transparent;
    }
}
</style>
