<i18n>
{
    "en-BE": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "average_epc": "Average EPC for that label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    },
    "en-FR": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "average_epc": "Average EPC for that label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    },
    "en-NL": {
        "basic_info": {
            "title": "Basic info",
            "building_type": {
                "label": "Building Type",
                "options": {
                    "house": "House",
                    "apartment": "Apartment"
                }
            },
            "building_found": "Building found",
            "level": "Level",
            "touching_sides": "Number of touching sides",
            "renovations": {
                "label": "Renovations",
                "warning": "Renovations costs > 15% of AVM price"
            }
        },
        "state": {
            "title": "State",
            "average_epc": "Average EPC for that label"
        },
        "size": {
            "title": "Size"
        },
        "apartment_features": {
            "title": "Apartment features"
        },
        "annexes": {
            "title": "Annexes"
        },
        "other": {
            "title": "Other",
            "f_dist_building_flood": "Building distance to nearest flood zone",
            "f_dist_parcel_flood": "Parcel distance to nearest flood zone",
            "f_matched_entities": "Matched economic entities"
        },
        "yes": "Yes",
        "no": "No"
    }
}
</i18n>
<template>
    <div class="is-w100">
        <form>
            <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                {{ $t('basic_info.title') }}
            </h2>
            <div class="is-flex is-justify-content-start is-align-items-start is-w100">
                <b-field :label="$t('basic_info.building_type.label')" class="mr-4 pl-0">
                    <b-select v-model="request.features.f_building_type">
                        <option value="apartment">
                            {{ $t('basic_info.building_type.options.apartment') }}
                        </option>
                        <option value="house">
                            {{ $t('basic_info.building_type.options.house') }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    :label="$t('basic_info.building_found')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input
                        disabled
                        :value="request.features.buildingnotfound ? 'No' : 'Yes'"
                    />
                </b-field>
                <b-field :label="$t('basic_info.level')" class="is-flex-grow-1">
                    <b-input type="number" disabled :value="request.address.level" />
                </b-field>
            </div>
            <div
                class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
            >
                <b-field
                    v-if="isHouse"
                    :label="$t('basic_info.touching_sides')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_touching_sides" type="number" />
                </b-field>
                <b-field
                    :label="$t('basic_info.renovations.label')"
                    class="is-flex-grow-1"
                    :type="request.features.renovation_cost ? 'is-danger' : null"
                >
                    <div class="is-flex is-flex-direction-column">
                        <b-input
                            :value="`${withSpaces(request.features.renovation_cost)}€`"
                            disabled
                        />
                        <h3
                            v-if="hasRenovationWarning"
                            class="is-size-5 has-text-weight-bold has-text-danger is-align-self-center"
                        >
                            {{ $t('basic_info.renovations.warning') }}
                        </h3>
                    </div>
                </b-field>
            </div>
            <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                {{ $t('state.title') }}
            </h2>
            <div
                class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
            >
                <b-field
                    v-if="isEpcLabel"
                    :label="$t('features.f_epc')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_epc_label" type="string" />
                </b-field>
                <b-field
                    :label="isEpcLabel ? $t('state.average_epc') : $t('features.f_epc')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input
                        v-model="request.features.f_epc"
                        type="number"
                        :disabled="isEpcLabel"
                    />
                </b-field>
                <b-field
                    :label="$t('features.f_construction_year')"
                    class="is-flex-grow-1"
                >
                    <b-input
                        v-model="request.features.f_construction_year"
                        type="number"
                    />
                </b-field>
            </div>
            <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium">
                {{ $t('size.title') }}
            </h2>
            <div class="is-flex is-justify-content-start is-align-items-start is-w100">
                <b-field
                    v-if="isHouse"
                    :label="$t('features.f_parcel_area')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_parcel_area" type="number" />
                </b-field>
                <b-field
                    v-if="isHouse"
                    :label="$t('features.f_garden_area')"
                    class="is-flex-grow-1"
                >
                    <b-input v-model="request.features.f_garden_area" type="number" />
                </b-field>
            </div>
            <div class="is-flex is-justify-content-start is-align-items-start is-w100">
                <b-field
                    :label="$t('features.f_living_area')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_living_area" type="number" />
                </b-field>
                <b-field
                    v-if="isHouse"
                    :label="$t('features.f_building_area')"
                    class="is-flex-grow-1"
                >
                    <b-input v-model="request.features.f_building_area" type="number" />
                </b-field>
            </div>
            <div
                class="is-flex is-justify-content-start is-align-items-start is-w100 mb-5"
            >
                <b-field
                    :label="$t('features.f_approx_living_area')"
                    class="is-flex-grow-1"
                >
                    <b-input
                        :value="
                            Math.floor(Math.floor(request.features.f_inner_volume) / 2.8)
                        "
                        type="number"
                        disabled
                    />
                </b-field>
            </div>
            <h2 v-if="isApt" class="is-size-4 has-text-black m-0 has-text-weight-medium">
                {{ $t('apartment_features.title') }}
            </h2>
            <div
                v-if="isApt"
                class="is-flex is-justify-content-start is-align-items-start is-w100"
            >
                <b-field
                    :label="$t('features.f_bottom_floor')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_bottom_floor" type="number" />
                </b-field>
                <b-field
                    :label="$t('features.f_top_floor')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_top_floor" type="number" />
                </b-field>
            </div>
            <range-selector
                v-if="isApt"
                v-model="request.features.f_floors"
                class="p-0 mb-2"
                :label="$t('features.f_floors')"
                :range-list="floor_list"
                :type="request.features.f_floors.length > 1 ? 'is-danger' : 'is-primary'"
                @input="updateFloors"
            />
            <div
                v-if="isApt"
                class="is-flex is-justify-content-start is-align-items-start is-w100"
            >
                <b-field
                    :label="$t('features.f_bedrooms')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                >
                    <b-input v-model="request.features.f_bedrooms" type="number" />
                </b-field>
                <b-field
                    :label="$t('features.f_n_closed_garage')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    :type="request.features.f_n_closed_garage ? 'is-danger' : null"
                >
                    <b-input v-model="request.features.f_n_closed_garage" type="number" />
                </b-field>
                <b-field
                    :label="$t('features.f_n_parking_spot')"
                    class="is-flex-grow-1 mr-4 ml-0 pl-0"
                    :type="request.features.f_n_parking_spot ? 'is-danger' : null"
                >
                    <b-input v-model="request.features.f_n_parking_spot" type="number" />
                </b-field>
            </div>
            <div
                v-if="isApt"
                class="is-flex is-justify-content-space-between is-align-items-start is-w100"
            >
                <radio-selector
                    v-model="request.features.f_cellar_attic"
                    :label="$t('features.f_cellar_attic')"
                    :options="[
                        {
                            label: $t('yes'),
                            value: true,
                        },
                        {
                            label: $t('no'),
                            value: false,
                        },
                    ]"
                />
                <radio-selector
                    v-model="request.features.f_balcony_present"
                    :label="$t('features.f_balcony_present')"
                    :options="[
                        {
                            label: $t('yes'),
                            value: true,
                        },
                        {
                            label: $t('no'),
                            value: false,
                        },
                    ]"
                />
                <radio-selector
                    v-model="request.features.f_garden_common"
                    :label="$t('features.f_garden_common')"
                    :options="[
                        {
                            label: $t('yes'),
                            value: true,
                        },
                        {
                            label: $t('no'),
                            value: false,
                        },
                    ]"
                />
                <radio-selector
                    v-model="request.features.f_garden_private"
                    :label="$t('features.f_garden_private')"
                    :options="[
                        {
                            label: $t('yes'),
                            value: true,
                        },
                        {
                            label: $t('no'),
                            value: false,
                        },
                    ]"
                />
            </div>
            <h2
                v-if="isHouse"
                class="is-size-4 has-text-black m-0 has-text-weight-medium mt-5"
            >
                {{ $t('annexes.title') }}
            </h2>
            <div
                v-if="isHouse"
                class="is-flex is-justify-content-start is-align-items-start is-w100"
            >
                <h5 class="is-flex-grow-1 mr-4 ml-0 pl-0">
                    {{ $t('features.f_x_annexes') }}: {{ request.features.f_x_annexes }}
                </h5>
                <h5 class="is-flex-grow-1">
                    {{ $t('features.f_annexes_area') }}:
                    {{ request.features.f_annexes_area || 0 }}
                </h5>
            </div>
            <h2 class="is-size-4 has-text-black m-0 has-text-weight-medium mt-5">
                {{ $t('other.title') }}
            </h2>
            <div class="columns">
                <div class="column is-6">
                    <h5
                        :class="
                            request.features.f_flood_risk.toLowerCase() !== 'none' &&
                                'has-text-danger has-text-weight-bold'
                        "
                    >
                        {{ $t('features.f_flood_risk') }}:
                        {{ $t(`value.${request.features.f_flood_risk.toLowerCase()}`) }}
                        <b-icon
                            v-if="request.features.f_flood_risk.toLowerCase() !== 'none'"
                            type="is-danger"
                            icon="alert-circle"
                        />
                    </h5>
                    <h5>
                        {{ $t('other.f_dist_building_flood') }}:
                        {{ request.features.f_dist_building_flood }} m
                    </h5>
                    <h5>
                        {{ $t('other.f_dist_parcel_flood') }}:
                        {{ request.features.f_dist_parcel_flood }} m
                    </h5>
                </div>
                <div class="column is-6">
                    <h5>
                        {{ $t('features.c_economic_activity_level') }}:
                        {{ $t(`value.${request.features.c_economic_activity_level}`) }}
                    </h5>
                    <h5>
                        {{ $t('other.f_matched_entities') }}:
                        {{ request.features.f_matched_entities.join(', ') }}
                    </h5>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import RangeSelector from '../elements/RangeSelector.vue'
import RadioSelector from '../elements/RadioSelector.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'DvmDescription',
    components: {
        RangeSelector,
        RadioSelector,
    },
    data() {
        return {
            request: {},
            valuation_request_ref: null,
            floors: [],
        }
    },
    computed: {
        ...mapGetters(['getFullStore']),
        isApt() {
            return this.request.features.f_building_type === 'apartment'
        },
        isHouse() {
            return this.request.features.f_building_type === 'house'
        },
        isEpcLabel() {
            return !!this.request.features.f_epc_label
        },
        floor_list() {
            let l = []
            for (
                let i = this.request.features?.f_bottom_floor || 0;
                i <= this.request.features.f_top_floor;
                i += 1
            ) {
                l.push(i)
            }
            return l
        },
        hasRenovationWarning() {
            return (
                this.request.features.renovation_cost >=
                this.request.avm_transaction.body.estimation.asking_price_q50 * 0.15
            )
        },
    },
    watch: {
        request: {
            deep: true,
            handler(val) {
                if (this.isHouse) {
                    val.features.f_parcel_area = +val.features.f_parcel_area
                    val.features.f_building_area = +val.features.f_building_area
                    val.features.f_touching_sides = +val.features.f_touching_sides
                }
                if (this.isApt) {
                    val.features.f_bedrooms = +val.features.f_bedrooms
                    val.features.f_bottom_floor = +val.features.f_bottom_floor
                    val.features.f_top_floor = +val.features.f_top_floor
                    val.features.f_n_closed_garage = +val.features.f_n_closed_garage
                    val.features.f_n_parking_spot = +val.features.f_n_parking_spot
                }
                val.features.f_epc = +val.features.f_epc
                val.features.f_epc_numeric = +val.features.f_epc
                val.features.f_construction_year = +val.features.f_construction_year
                val.features.f_living_area = +val.features.f_living_area
                this.SET_FEATURES(val.features)
            },
        },
    },
    mounted() {
        this.request = this.getFullStore.Address.data
        if (this.getFullStore.Address.data.features.f_building_type === 'apartment') {
            this.floors = [...this.getFullStore.Address.data.features.f_floors]
        }
        this.valuation_request_ref = this.getFullStore.valuation_request_ref
    },
    methods: {
        ...mapMutations('Address', ['SET_FEATURES']),
        updateFloors(floors) {
            this.request.features.f_floors = floors
        },
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
    },
}
</script>
