<i18n>
{
    "en-BE": {
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetiew data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        }
    },
    "en-FR": {
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetiew data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        }
    },
    "en-NL": {
        "price_per_sqm": "Price per sqm",
        "address": "Address",
        "report": "Report",
        "anchors": {
            "flood_map": "Flood map",
            "description": "Description",
            "avm": "AVM",
            "dvm": "Desktop Valuation"
        },
        "actions": {
            "resubmit": "Resubmit",
            "onsite": "Request on site valuation",
            "incoherent_features": "Select incoherent features",
            "submit": "Submit valuation"
        },
        "incoherent_features_modal": {
            "title": "Incoherent features",
            "confirmation_message": "Valuation request sent for review!",
            "submit": "Send incoherent features",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        },
        "onsite_modal": {
            "title": "Request on-site valuation",
            "reasons": {
                "onsite_streetview": "Streetiew data not available or outdated",
                "onsite_incoherent_features": "Wrong input filled",
                "onsite_exceptional_property": "Exceptional property for the area",
                "onsite_commercial_building": "Commercial building",
                "onsite_volume_missing": "Building volume missing",
                "onsite_flood_zone": "Flood zone risk",
                "onsite_few_comparison": "Not enough comparison data points",
                "onsite_extensive_renovation": "Ratio renovation/current value",
                "onsite_others": "Other"
            },
            "confirmation_message": "Valuation request deferred on-site!",
            "submit": "Defer to on-site",
            "cancel": "Cancel",
            "back_to_dashboard": "Go back to the Dashboard"
        }
    }
}
</i18n>

<template>
    <div id="valuation-tool">
        <div class="columns is-centered mt-1">
            <div class="column pb-0 pr-1 is-2 is-narrow sticky-column">
                <div class="card p-2 mb-2 mt-2">
                    <div>{{ $t('features.f_epc') }}: {{ request.features.f_epc }}</div>
                    <div>
                        {{ $t('features.f_construction_year') }}:
                        {{ request.features.f_construction_year }}
                    </div>
                    <div>
                        {{ $t('features.f_living_area') }}:
                        {{ request.features.f_living_area }} m&sup2;
                    </div>
                    <div>{{ $t('price_per_sqm') }}: {{ withSpaces(pricePerSqm) }} €</div>
                    <div>
                        <h5>{{ $t('address') }}:</h5>
                        <span>
                            {{ request.address.full_address }}
                        </span>
                        <b-button size="is-small" @click="copyAddress">
                            <b-icon icon="content-paste" size="is-small" />
                        </b-button>
                    </div>
                    <div
                        v-if="getFullStore.request_language"
                        class="has-text-weight-bold"
                    >
                        [{{ getFullStore.request_language }}]
                    </div>
                </div>

                <div class="card mb-2 p-2">
                    <div>
                        <router-link
                            :to="{
                                name: 'request',
                                params: {
                                    action: 'extra-info',
                                    lang: $route.params.lang,
                                },
                                query: {
                                    valuation_request_ref,
                                },
                            }"
                            target="_blank"
                        >
                            {{ valuation_request_ref }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'report',
                                query: {
                                    valuation_request_ref,
                                },
                            }"
                            target="_blank"
                        >
                            {{ $t('report') }}
                        </router-link>
                    </div>
                    <div>
                        <a
                            :href="
                                `https://maps.google.com/?q=${request.features.f_lat},${request.features.f_lng}&ll=${request.features.f_lat},${request.features.f_lng}&z=19`
                            "
                            target="_blank"
                        >
                            Google Maps
                        </a>
                    </div>
                </div>

                <div class="card links mb-2 p-2">
                    <a href="#flood-map">{{ $t('anchors.flood_map') }}</a>
                    <a href="#description">{{ $t('anchors.description') }}</a>
                    <a href="#avm">{{ $t('anchors.avm') }}</a>
                    <a href="#dvm">{{ $t('anchors.dvm') }}</a>
                </div>

                <b-field :label="$t('dvm_features.internal_remarks')" class="mx-0 mb-4">
                    <b-input v-model="internal_remarks" type="textarea" rows="8" lazy />
                </b-field>

                <div
                    class="button-group is-flex is-flex-direction-column is-align-items-stretch"
                >
                    <b-button class="mb-2" type="is-info" @click="resubmit">
                        {{ $t('actions.resubmit') }}
                    </b-button>
                    <b-button class="mb-2" type="is-danger" @click="requestOnSite">
                        {{ $t('actions.onsite') }}
                    </b-button>
                    <b-button
                        class="mb-2"
                        type="is-warning"
                        @click="showIncoherentFeatures"
                    >
                        {{ $t('actions.incoherent_features') }}
                    </b-button>
                </div>
            </div>

            <div class="column pb-0 pr-0 is-8 pb-5">
                <div id="flood-map" class="map-container mt-2">
                    <re-map
                        :building-id="request.features.building_id"
                        :parcel-ids="request.features.parcel_ids"
                        :auto-select-parcels="false"
                        :level="request.features.level"
                        :map-scene2d="map_scene2d"
                        :center="{
                            lat: request.features.f_lat,
                            lng: request.features.f_lng,
                        }"
                        :read-only="true"
                        :focus-layer="'building'"
                        class="has-ratio"
                        :layers-control-visible="false"
                        :options="{
                            zoomControl: true,
                            attributionControl: false,
                            scrollWheelZoom: false,
                        }"
                        :region="request.features.region"
                        :aerial-tile-visible="false"
                        :cadastral-tile-visible="false"
                    ></re-map>
                </div>
                <div id="description" class="pt-5">
                    <description />
                </div>
                <div id="avm" class="pt-5">
                    <avm
                        :avm-rental="request.avm_rental.body"
                        :avm-transaction="request.avm_transaction.body"
                        :features="request.features"
                        :address="address"
                        :reconstruction-value="reconstruction_value"
                        :forced-sale-value="forced_sale_value"
                        :update-deviation="updateAvmFeaturesDeviation"
                        :update-remarks="setInternalRemarks"
                    />
                </div>
                <div id="dvm" class="pt-5 is-full-width">
                    <desktop-value
                        :features="request.features"
                        :prices="dvmPrices"
                        :update-remarks="setInternalRemarks"
                        :avm-deviation="avmFeaturesDeviation"
                    />
                </div>
                <div class="is-flex is-flex-direction-column is-align-items-stretch">
                    <b-button type="is-success has-flex-grow-2" @click="submitValuation">
                        {{ $t('actions.submit') }}
                    </b-button>
                </div>
            </div>
        </div>

        <b-modal
            v-model="isIncoherentModalShown"
            :on-cancel="closeIncoherentModal"
            width="640px"
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('incoherent_features_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="closeIncoherentModal" />
                </header>
                <section class="modal-card-body">
                    <div v-if="!incoherentFeaturesSent" class="incoherent-features-grid">
                        <b-field
                            label="EPC"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_epc ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_epc" />
                        </b-field>
                        <b-switch
                            v-model="incoherentFeatures.f_epc"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            label="Construction year"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="
                                incoherentFeatures.f_construction_year ? 'is-danger' : ''
                            "
                        >
                            <b-input
                                disabled
                                :value="request.features.f_construction_year"
                            />
                        </b-field>
                        <b-switch
                            v-model="incoherentFeatures.f_construction_year"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            label="Living area"
                            custom-class="mb-0 pt-0"
                            :type="incoherentFeatures.f_living_area ? 'is-danger' : ''"
                            class="mb-0 is-align-items-center"
                        >
                            <b-input disabled :value="request.features.f_living_area" />
                        </b-field>
                        <b-switch
                            v-model="incoherentFeatures.f_living_area"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="!isApartment"
                            label="Parcel area"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_parcel_area ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_parcel_area" />
                        </b-field>
                        <b-switch
                            v-if="!isApartment"
                            v-model="incoherentFeatures.f_parcel_area"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="!isApartment"
                            label="Garden area"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_garden_area ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_garden_area" />
                        </b-field>
                        <b-switch
                            v-if="!isApartment"
                            v-model="incoherentFeatures.f_garden_area"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="!isApartment"
                            label="Building area"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_building_area ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_building_area" />
                        </b-field>
                        <b-switch
                            v-if="!isApartment"
                            v-model="incoherentFeatures.f_building_area"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Bottom floor"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_bottom_floor ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_bottom_floor" />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_bottom_floor"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Top floor"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_top_floor ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.f_top_floor" />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_top_floor"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Floors"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_floors ? 'is-danger' : ''"
                        >
                            <b-input
                                disabled
                                :value="request.features.f_floors.toString()"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_floors"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Access to a common garden"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_garden_common ? 'is-danger' : ''"
                        >
                            <b-input
                                disabled
                                :value="request.features.f_garden_common ? 'Yes' : 'No'"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_garden_common"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Access to a private garden"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_garden_private ? 'is-danger' : ''"
                        >
                            <b-input
                                disabled
                                :value="request.features.f_garden_private ? 'Yes' : 'No'"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_garden_private"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Number of closed garages"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="
                                incoherentFeatures.f_n_closed_garage ? 'is-danger' : ''
                            "
                        >
                            <b-input
                                disabled
                                :value="request.features.f_n_closed_garage"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_n_closed_garage"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Number of parking spots"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_n_parking_spot ? 'is-danger' : ''"
                        >
                            <b-input
                                disabled
                                :value="request.features.f_n_parking_spot"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_n_parking_spot"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="isApartment"
                            label="Cellar and/or attic"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.f_cellar_attic ? 'is-danger' : ''"
                        >
                            <b-input
                                disabled
                                :value="request.features.f_cellar_attic ? 'Yes' : 'No'"
                            />
                        </b-field>
                        <b-switch
                            v-if="isApartment"
                            v-model="incoherentFeatures.f_cellar_attic"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />

                        <b-field
                            v-if="request.features.renovation_cost"
                            label="Renovation cost"
                            custom-class="mb-0 pt-0"
                            class="mb-0 is-align-items-center"
                            :type="incoherentFeatures.renovation_cost ? 'is-danger' : ''"
                        >
                            <b-input disabled :value="request.features.renovation_cost" />
                        </b-field>
                        <b-switch
                            v-if="request.features.renovation_cost"
                            v-model="incoherentFeatures.renovation_cost"
                            type="is-danger"
                            class="mb-2 mr-0"
                        />
                    </div>
                    <div v-else class="">
                        {{ $t('incoherent_features_modal.confirmation_message') }}
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-field v-if="!incoherentFeaturesSent" label="Optional comments">
                        <b-input v-model="rejection_comments" type="textarea" :rows="3" />
                    </b-field>
                    <div>
                        <b-button
                            v-if="!incoherentFeaturesSent"
                            :label="$t('incoherent_features_modal.submit')"
                            type="is-danger"
                            :disabled="!isIncoherentSubmitEnabled"
                            @click="submitIncoherentFeatures"
                        />
                        <b-button
                            v-if="!incoherentFeaturesSent"
                            :label="$t('incoherent_features_modal.cancel')"
                            @click="closeIncoherentModal"
                        />
                        <b-button
                            v-else
                            :label="$t('incoherent_features_modal.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>

        <b-modal v-model="isOnSiteModalShown" :on-cancel="cancelOnSite" width="640px">
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ $t('onsite_modal.title') }}
                    </p>
                    <button type="button" class="delete" @click="cancelOnSite" />
                </header>
                <section class="modal-card-body">
                    <div v-if="!onSiteSent">
                        <b-field label="Select on-site reason">
                            <div class="is-flex is-flex-direction-column">
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_streetview"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_streetview') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_incoherent_features"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_incoherent_features'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_exceptional_property"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_exceptional_property'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_commercial_building"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_commercial_building'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_volume_missing"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_volume_missing') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_flood_zone"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_flood_zone') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_few_comparison"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_few_comparison') }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_extensive_renovation"
                                    class="mb-2"
                                >
                                    {{
                                        $t(
                                            'onsite_modal.reasons.onsite_extensive_renovation'
                                        )
                                    }}
                                </b-radio>
                                <b-radio
                                    v-model="onsite_reasons"
                                    native-value="onsite_others"
                                    class="mb-2"
                                >
                                    {{ $t('onsite_modal.reasons.onsite_others') }}
                                </b-radio>
                            </div>
                        </b-field>
                    </div>
                    <div v-else>
                        {{ $t('onsite_modal.confirmation_message') }}
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <b-field v-if="!onSiteSent" label="Optional comments">
                        <b-input v-model="rejection_comments" type="textarea" :rows="3" />
                    </b-field>
                    <div>
                        <b-button
                            v-if="!onSiteSent"
                            :label="$t('onsite_modal.submit')"
                            type="is-danger"
                            :disabled="!onsite_reasons"
                            @click="confirmOnSite"
                        />
                        <b-button
                            v-if="!onSiteSent"
                            :label="$t('onsite_modal.cancel')"
                            @click="cancelOnSite"
                        />
                        <b-button
                            v-else
                            :label="$t('onsite_modal.back_to_dashboard')"
                            @click="backToDashboard"
                        />
                    </div>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import Description from './description.vue'
import Avm from './avm.vue'
import DesktopValue from './DesktopValue.vue'
import ReMap from '../common/Map.vue'
import utils from '@/shared/plugins/utils'

export default {
    name: 'DVM',
    components: {
        Description,
        Avm,
        DesktopValue,
        ReMap,
    },
    data() {
        return {
            activeTab: 0,
            request: {},
            valuation_request_ref: null,
            address: {},
            reconstruction_value: 0,
            internal_remarks: '',
            valuer_remarks: '',
            avmFeaturesDeviation: 0,
            isOnSiteModalShown: false,
            onsite_reasons: null,
            onSiteSent: false,
            isIncoherentModalShown: false,
            incoherentFeaturesSent: false,
            incoherentFeatures: {
                f_epc: false,
                f_construction_year: false,
                f_living_area: false,
                f_parcel_area: false,
                f_garden_area: false,
                f_building_area: false,
                f_bottom_floor: false,
                f_top_floor: false,
                f_floors: false,
                f_garden_common: false,
                f_garden_private: false,
                f_n_closed_garage: false,
                f_n_parking_spot: false,
                f_cellar_attic: false,
                renovation_cost: false,
            },
            rejection_comments: '',
            map_scene2d: {
                parcel_geojson: null,
                building_geojson: null,
                address_geojson: null,
                flood_geojson: null,
            },
        }
    },
    computed: {
        ...mapGetters(['getFullStore']),
        ...mapGetters('Address', ['getFullBuildingData']),
        isIncoherentSubmitEnabled() {
            let returnBool = false

            Object.keys(this.incoherentFeatures).forEach((key) => {
                if (this.incoherentFeatures[key] == true) {
                    returnBool = true
                }
            })

            return returnBool
        },
        isApartment() {
            return this.request.features?.f_building_type === 'apartment'
        },
        pricePerSqm() {
            return Math.round(
                this.request.avm_transaction.body.estimation.asking_price_q50 /
                    this.request.features.f_living_area
            )
        },
        forced_sale_value() {
            const x =
                this.request.avm_transaction.body.estimation.asking_price_q50 / 1000000
            let y
            if (x < 0.2) {
                y = 1 - x
            } else if (x < 0.4) {
                y = 0.9 - x / 2
            } else {
                y = 0.8 - x / 4
            }
            // Above lines are the best way we found to replace numpy.interp for more than just one segment
            // x vector is [100000, 200000, 400000, 800000]
            // y vector is [0.9, 0.8, 0.7, 0.6]
            // We divide by 1MIL before doing operations to avoid having too many zeroes lying around

            return (
                Math.round(
                    (y * this.request.avm_transaction.body.estimation.asking_price_q50) /
                        1000
                ) * 1000
            )
        },
        dvmPrices() {
            const prices = {}
            prices.transaction_price =
                Math.round(
                    this.request.avm_transaction.body.estimation.asking_price_q50 / 1000
                ) * 1000
            prices.forced_sale = this.forced_sale_value
            prices.rental_value =
                Math.round(
                    this.request.avm_rental.body.estimation.asking_price_q50 / 10
                ) * 10
            prices.reconstruction_value = this.reconstruction_value

            return prices
        },
    },
    watch: {
        internal_remarks(val) {
            this.SET_DVM_FEATURES({ internal_remarks: val })
        },
    },
    mounted() {
        this.request = this.getFullStore.Address.data
        this.valuation_request_ref = this.getFullStore.valuation_request_ref
        this.address = this.request.address
        this.getReconstructionValue()
        this.map_scene2d = this.getFullStore.Address.data.map_scene2d
        if (
            this.request.dvm_features.internal_remarks &&
            !utils.isEmptyStr(this.request.dvm_features.internal_remarks)
        ) {
            this.internal_remarks = this.request.dvm_features.internal_remarks
        }
        this.request.avm_transaction.body.reference_listings.forEach((ref, i) => {
            let url = utils.urlJoin(process.env.VUE_APP_API_URL, 'reverse_geocode')
            url += `?lat=${ref.lat}&lng=${ref.lng}`
            axios.get(url).then((res) => {
                this.request.avm_transaction.body.reference_listings[i].address = res.data
            })
        })
    },
    methods: {
        ...mapMutations('Address', [
            'UPDATE_DVM_REJECTIONS',
            'SET_DVM_FEATURES',
            'SET_VALUATION',
        ]),
        ...mapActions(['updateStore']),
        withSpaces(number) {
            return utils.numberWithSpaces(number)
        },
        copyAddress() {
            this.$copyText(this.request.address.full_address).then(
                () => {
                    this.onAddressCopy()
                },
                () => {
                    this.onCopyError()
                }
            )
        },
        onAddressCopy() {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Copied to clipboard!',
                position: 'is-bottom',
                type: 'is-success',
            })
        },
        onCopyError() {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Something went wrong, please get in touch with the tech team',
                position: 'is-bottom',
                type: 'is-danger',
            })
        },
        getReconstructionValue() {
            let url = utils.urlJoin(process.env.VUE_APP_API_URL, [
                'estimate',
                'reconstruction',
                this.request.features.building_id,
            ])
            url += `?f_building_type=${this.request.features.f_building_type}&f_living_area=${this.request.features.f_living_area}`
            axios.get(url).then((res) => {
                this.reconstruction_value =
                    Math.round(res.data.reconstruction_value / 1000) * 1000
            })
        },
        setInternalRemarks(remarks) {
            if (!utils.isEmptyStr(this.internal_remarks)) this.internal_remarks += '\n'

            this.internal_remarks += remarks

            this.SET_DVM_FEATURES({ internal_remarks: this.internal_remarks })
        },
        updateAvmFeaturesDeviation(val) {
            this.avmFeaturesDeviation = val
        },
        async submitValuation() {
            const now = new Date()
            const valuationDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const valuation = {
                date: valuationDate,
                type: 'dvm',
                valuer: this.$store.state.auth.email,
            }

            this.SET_VALUATION(valuation)

            await this.updateStore({
                data: this.getFullBuildingData,
                lang: this.$i18n.locale,
                action: 'submit-dvm-valuation',
            })

            this.$router.push({
                name: 'report',
                params: {
                    lang: this.$route.params.lang,
                },
                query: {
                    valuation_request_ref: this.valuation_request_ref,
                    static: 'true',
                },
            })
        },
        showIncoherentFeatures() {
            this.isIncoherentModalShown = true
        },
        closeIncoherentModal() {
            this.isIncoherentModalShown = false
        },
        async submitIncoherentFeatures() {
            let incoherent_features = {}

            Object.keys(this.incoherentFeatures).forEach((key) => {
                if (this.incoherentFeatures[key]) {
                    incoherent_features[key] = this.request.features[key]
                }
            })

            const now = new Date()
            const rejectionDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const rejection = {
                date: rejectionDate,
                valuer: this.$store.state.auth.email,
                incoherent_features,
                rejection_comments: this.rejection_comments,
            }

            this.UPDATE_DVM_REJECTIONS(rejection)

            await this.updateStore({
                data: this.getFullBuildingData,
                lang: this.$i18n.locale,
                action: 'flag-for-review',
            })

            this.incoherentFeaturesSent = true
        },
        requestOnSite() {
            this.isOnSiteModalShown = true
        },
        cancelOnSite() {
            this.isOnSiteModalShown = false
        },
        async confirmOnSite() {
            const now = new Date()
            const rejectionDate = `${now.getFullYear()}-${now.getMonth() +
                1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

            const rejection = {
                date: rejectionDate,
                valuer: this.$store.state.auth.email,
                onsite_reasons: this.onsite_reasons,
                rejection_comments: this.rejection_comments,
            }

            this.UPDATE_DVM_REJECTIONS(rejection)

            await this.updateStore({
                data: this.getFullBuildingData,
                lang: this.$i18n.locale,
                action: 'defer-to-onsite',
            })

            this.onSiteSent = true
        },
        async resubmit() {
            await this.updateStore({
                data: this.getFullBuildingData,
                lang: this.$i18n.locale,
                action: 'submit',
            })

            this.$router.go()
        },
        backToDashboard() {
            this.$router.push({ name: 'dashboard' })
        },
    },
}
</script>
<style lang="scss" scoped>
.sticky-column {
    position: sticky;
    top: 0.2rem;
    height: fit-content;
}
.links {
    display: flex;
    flex-direction: column;
}
.map-container {
    height: 40vh;
    width: 100%;
}
.incoherent-features-grid {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: end;
    column-gap: 0.5rem;
    row-gap: 1rem;
}
.modal-card-foot {
    display: block;
}
</style>
